// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"

console.log('loady loady');

$(function() {
	$('.match_users').on('click', function() {
		$.post( `${window.location}/match`, function() {
		})			
		.done(function() {
				console.log("done");
			$('span.success_fail').text('Matching successful, reload to see your match.');
		});
	});
});
